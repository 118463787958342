import React from 'react';
import ActionLink from '../../_global/ActionLink.jsx';
import './FakeCommentBox.scss';
import hello from '../../../images/profile.svg';

class FakeCommentBox extends React.Component {

    static defaultProps = {
        comments: [
            `Imagine a cleaner, prettier Disqus, but without the ads, tracking, or sharing of personal data. That's us!`,
            `We won't follow you around the web like a lost puppy. A creepy puppy who analyzes everything you do to sell you things.`,
            `User consent done right. If you like seeing unwanted emails and cookie disclaimers, we're not for you.`,
            `Powerful moderation tools to make managing spam and toxicity fun! Well, maybe just with a little less suck.`,
            `It's really just a nice box to put your internet words in.`,
        ]
    };

    textArea = React.createRef();

    state = {
        body: '',
        anonymous: false,
        subscribe: false,
        commentIndex: this.props.comments.length - 1,
        bodyPosition: 0
    };

    componentDidMount() {

        this.newComment();
    }

    newComment = () => {

        let commentIndex = this.state.commentIndex + 1;
        if (commentIndex >= this.props.comments.length) {
            commentIndex = 0;
        }

        //this.textArea.current.style.height = 'auto';

        this.setState({ body: '' }, () => {

            setTimeout(() => {
                this.setState({ bodyPosition: 0, commentIndex }, this.type);
            }, 1000);
        });
    };

    type = () => {

        const bodyPosition = this.state.bodyPosition + 1;
        const comment = this.props.comments[this.state.commentIndex];
        const delay = (
            comment.charAt(this.state.bodyPosition) === '.' || comment.charAt(this.state.bodyPosition) === '!'
            )
            && comment.charAt(bodyPosition) === ' ';

        this.setState({
            body: comment.slice(0, bodyPosition),
            bodyPosition
        }, () => {

            // this.textArea.current.style.height = 'auto';
            // this.textArea.current.style.height = `${this.textArea.current.scrollHeight}px`;

            if (bodyPosition === comment.length) {
                return setTimeout(() => this.newComment(), 1500);
            }
            return setTimeout(() => this.type(), delay ? 500 : 35);
        });
    };

    onChangeCheckbox = ({ currentTarget: { value, checked } }) => {

        this.setState({ [value]: checked });
    };

    render() {
        return (
            <div className="fake-comment-box">
                <div className="media">
                    <div className="media-left">
                        <a href="#">
                            <img className="media-object" src={hello} alt="profile picture" />
                        </a>
                    </div>
                    <div className="media-body">
                        <div className={`textarea-container${this.state.body ? ' focused' : ''}`}>
                            <textarea
                                placeholder="Leave a comment"
                                readOnly={true}
                                value={this.state.body}
                                ref={this.textArea}
                            />
                        </div>
                        <div className="actions clearfix">
                            <ActionLink href="#demo" className="btn btn-success pull-left">
                                <span className="secondary">Try the Live Demo</span>
                                <span className="post-comment">Post Comment</span>
                            </ActionLink>
                            <div className="pull-right hidden-xs">
                                <div className="checkbox">
                                    <label>
                                        <input
                                            type="checkbox"
                                            value="anonymous"
                                            checked={this.state.anonymous}
                                            onChange={this.onChangeCheckbox}
                                        />
                                        <span className="icon-person" /> Anonymous
                                    </label>
                                </div>
                                <div className="checkbox">
                                    <label>
                                        <input
                                            type="checkbox"
                                            value="subscribe"
                                            checked={this.state.subscribe}
                                            onChange={this.onChangeCheckbox}
                                        />
                                        <span className="icon-notifications_active" /> Email replies
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FakeCommentBox;