import React from 'react';
import ActionLink from './ActionLink.jsx';

import './Next.scss';

const Next = props => (
    <div className="next">
        <div className="container">
            <div className="row">
                <div className="col-xs-12">
                    <ActionLink {...props}>
                        <span className="glyphicon glyphicon-chevron-down" />
                    </ActionLink>
                </div>
            </div>
        </div>
    </div>
);

export default Next;