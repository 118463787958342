import React from 'react';

import './Slideshow.scss';

class Slideshow extends React.Component {

    static defaultProps = {
        slides: []
    };

    get slides() {

        if (this.props.slides.length === 0) {
            return null;
        }

        return this.props.slides.map((slide, index) => (
            <div key={slide} className="slide" style={{ zIndex: this.props.slides.length - index }}>
                <img src={slide} />
                <div className="feature-label visible-xs">
                    <h5>Dashboard Screenshot</h5>
                </div>
            </div>
        ));
    }

    render() {
        return (
            <div className="slideshow">
                {this.slides}
            </div>
        );
    }
}

export default Slideshow;