import React from 'react';

import './FeatureContainer.scss';

function FeatureContainer({ imgSrc, imgAlt, title, description }) {

    return (
        <article className="feature-container">
            <div className="panel panel-default">
                <div className="panel-body">

                    <div className="row">

                        <div className="col-lg-3">

                            <div className="image-container">
                                <img src={imgSrc} alt={imgAlt} />
                            </div>

                        </div>

                        <div className="col-lg-9">

                            <h3>
                                { title }
                            </h3>
                            <p>
                                { description }
                            </p>

                        </div>

                    </div>

                </div>
            </div>

        </article>
    );
}

export default FeatureContainer;