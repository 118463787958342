import React from 'react';
import {Link} from 'gatsby';

import './Header.scss';

function Header({ children }) {

    return (
        <header>
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <nav>

                            <div className="clearfix">
                                <div className="pull-right">

                                    <Link className="btn btn-link" to="/blog">Blog</Link>

                                    <Link className="btn btn-link" to="/docs">Docs</Link>

                                    <a href="https://dashboard.commentbox.io" target="_blank" className="btn btn-success">
                                        Dashboard
                                    </a>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="content">
                { children }
            </div>
        </header>
    );
}

export default Header;