import React from 'react';
import { Link } from 'gatsby';
import commentBox from 'commentbox.io';
import MainLayout from '../layouts/MainLayout.jsx';
import Divider from '../_global/Divider.jsx';
import Header from '../_global/Header.jsx';
import Next from '../_global/Next.jsx';
import Slideshow from '../_global/Slideshow.jsx';
import Code from '../_global/Code.jsx';

import FakeCommentBox from './home/FakeCommentBox.jsx';
import FeatureContainer from './home/FeatureContainer.jsx';
import Plan from './home/PaidPlan.jsx';

import './HomePage.scss';

import logo from '../../images/logo.svg';
import users from '../../images/users-first/undraw_inbox_cleanup_w2ur.svg';
import beauty from '../../images/beauty-beast/undraw_messages1_9ah2.svg';
import mod from '../../images/moderation/undraw_work_chat_erdt.svg';
import millions from '../../images/beauty-beast/undraw_news_go0e.svg';
import savings from '../../images/savings.svg';
import personal from '../../images/personal.svg';
import professional from '../../images/professional.svg';
import moderator  from '../../images/moderator.svg';
import graphic from '../../images/beauty-beast/undraw_development_ouy3.svg';

import basicInfo from '../../images/showcase/basic-info.png';
import moderationRules from '../../images/showcase/moderation-rules.png';
import optional from '../../images/showcase/optional.png';
import comments from '../../images/showcase/whitebg.png';

import redundant from '../../images/redundant.svg';
import exportComments from '../../images/export.svg';
import webhooks from '../../images/webhooks.svg';


const html = `
<!-- paste this snippet where you want your comment box to show up -->
<div class="commentbox"></div>
<script src="https://unpkg.com/commentbox.io/dist/commentBox.min.js"></script>
<script>commentBox('my-project-id')</script>
`.trim();

class HomePage extends React.Component {

    componentDidMount() {

        this.removeCommentBox = commentBox(process.env.GATSBY_PROJECT_ID);
    }

    componentWillUnmount() {

        this.removeCommentBox();
    }

    render() {

        return (
            <MainLayout>
                <div className="home-page">
                    <Header>

                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="hero-title">
                                        <h1>
                                            <span>No ads.</span> <span>No Tracking.</span> <span>Just Comments.</span>
                                        </h1>
                                    </div>
                                </div>
                                <div className="col-xs-12">
                                    <h4 className="hero-description">
                                        Hosted commenting that's painless to embed, a pleasure to use, and a breeze to moderate.
                                    </h4>
                                </div>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1">
                                    <div className="comment-box">
                                        <div className="box-title">
                                            <img src={logo} alt="CommentBox.io" />
                                        </div>
                                        <div className="box-content">
                                            <FakeCommentBox />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Next href="#features" />
                    </Header>

                    <section id="features">


                        <div className="container">
                            <div className="row">

                                <div className="col-lg-6 col-md-6 col-sm-6">

                                    <FeatureContainer
                                        imgSrc={users}
                                        imgAlt="Sorting through emails"
                                        title="Users Come First."
                                        description="Comments are our business. Not ads, not data. And no emails you didn't ask for."
                                    />

                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-6">

                                    <FeatureContainer
                                        imgSrc={beauty}
                                        imgAlt="Lots of comments in a list"
                                        title="Both Beauty and Beast."
                                        description="Clean, modern, and subtle enough to blend in with your aesthetic."
                                    />

                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-6">

                                    <FeatureContainer
                                        imgSrc={mod}
                                        imgAlt="Putting together comments"
                                        title="Moderation is Key."
                                        description="Tame the wild west of internet comments with granular comment filters and blocking."
                                    />

                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-6">

                                    <FeatureContainer
                                        imgSrc={redundant}
                                        imgAlt="Server status"
                                        title="Always Ready."
                                        description="Comments are saved in highly-available, multi-region storage."
                                    />

                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-6">

                                    <FeatureContainer
                                        imgSrc={exportComments}
                                        imgAlt="Girl on moped with package"
                                        title="No Lock-in."
                                        description="Export your comments on demand, directly from the dashboard."
                                    />

                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-6">

                                    <FeatureContainer
                                        imgSrc={webhooks}
                                        imgAlt="Spaceship in space"
                                        title="Extensible."
                                        description="Use webhooks to automatically send your comments anywhere."
                                    />

                                </div>
                            </div>
                        </div>

                        <Next href="#showcase" />

                    </section>


                    <section id="showcase">

                        <div className="container">

                            <div className="line visible-lg visible-md" />

                            <div className="row setup">
                                <div className="col-lg-3 col-md-3">
                                    <div className="tagline">
                                        <h2>
                                            Simple Setup.
                                        </h2>
                                    </div>
                                </div>
                                <div className="col-lg-9 col-md-9">
                                    <div className="content">
                                        <Slideshow slides={[ basicInfo, moderationRules, optional ]} />
                                    </div>
                                </div>
                            </div>

                            <div className="row embed">
                                <div className="col-lg-3 col-md-3">
                                    <div className="tagline">
                                        <h2>
                                            Easy Embed.
                                        </h2>
                                    </div>
                                </div>
                                <div className="col-lg-9 col-md-9">

                                    <div className="content">
                                        <div className="embed-code">
                                            <h5>HTML</h5>
                                            <Code language="html" content={html} />
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="row ui">
                                <div className="col-lg-3 col-md-3">
                                    <div className="tagline">
                                        <h2>
                                            Intuitive UI.
                                        </h2>
                                    </div>
                                </div>
                                <div className="col-lg-9 col-md-9">

                                    <div className="content">

                                        <div className="row">


                                            <div className="col-lg-6 col-md-6 col-sm-12">

                                                <div className="comments-sample">
                                                    <img src={comments} alt="People commenting intuitively" />

                                                    <div className="bracket hidden-xs" />

                                                    <div className="feature-label visible-xs">
                                                        <h5>Plugin Screenshot</h5>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-lg-6 col-md-6 col-sm-12">

                                                <div className="feature-list">

                                                    <div className="bracket hidden-xs" />

                                                    <ul>
                                                        <li>
                                                            Choose from social or email login
                                                        </li>
                                                        <li>
                                                            Create comments and replies
                                                        </li>
                                                        <li>
                                                            Vote, flag, and pin comments
                                                        </li>
                                                        <li>
                                                            Optional anonymous posting
                                                        </li>
                                                        <li>
                                                            Optional reply notifications
                                                        </li>
                                                        <li>
                                                            Moderate directly in the plugin
                                                        </li>
                                                    </ul>

                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>


                        <Next href="#pricing" />


                    </section>


                    <section id="pricing">

                        <div className="header">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="content">
                                            <h2>
                                                Pricing that's simple and transparent.
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container">

                            <div className="row">

                                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">

                                    <div className="plans">

                                        <div className="panel panel-default">
                                            <div className="panel-body">

                                                <div className="clearfix">

                                                    <div className="plan pull-left with-divider">

                                                        <h3>
                                                            Personal
                                                        </h3>

                                                        <hr />

                                                        <img src={personal} alt="Relaxing at home" />

                                                        <h2>
                                                            Free
                                                        </h2>

                                                        <div className="sub">

                                                            <span className="label label-default">
                                                                Within comment limit
                                                            </span>

                                                        </div>

                                                        <ul>
                                                            <li>
                                                                100 comments / month
                                                            </li>
                                                            <li>
                                                                Unlimited page views
                                                            </li>
                                                            <li>
                                                                Basic moderation tools
                                                            </li>
                                                            <li>
                                                                $15 if over comment limit
                                                            </li>
                                                            <li>
                                                                No credit card required at sign-up
                                                            </li>
                                                        </ul>

                                                    </div>

                                                    <Plan className="hidden-xs" />


                                                </div>


                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div className="col-xs-12 visible-xs-block with-padding">

                                    <div className="panel panel-default">

                                        <div className="panel-body">

                                            <Plan />

                                        </div>
                                    </div>

                                </div>

                                <div className="col-lg-2 col-md-12 col-sm-12 col-xs-12">

                                    <div className="text-center">

                                        <h1>
                                            <span className="glyphicon glyphicon-plus" />
                                        </h1>

                                        <h5>
                                            Optional Add-ons
                                        </h5>

                                    </div>

                                </div>


                                <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">

                                    <div className="panel panel-default moderator">

                                        <div className="panel-body">

                                            <div className="plan">

                                                <h3>
                                                    Moderation Plus
                                                </h3>

                                                <hr />

                                                <img src={moderator} alt="Bouncer" />

                                                <h2>
                                                    $20 / month
                                                </h2>

                                                <div className="sub">

                                                    <span className="label label-default">
                                                        Per 5K comments
                                                    </span>

                                                </div>

                                                <ul>
                                                    <li>
                                                        Granular moderation filters
                                                    </li>
                                                    <li>
                                                        "Shadow ban" users
                                                    </li>
                                                    <li>
                                                        Lock and disable threads
                                                    </li>
                                                    <li>
                                                        Search and filtering capability
                                                    </li>
                                                    <li>
                                                        Up to nine extra moderators
                                                    </li>
                                                </ul>

                                            </div>

                                        </div>

                                    </div>

                                </div>



                            </div>


                            <div className="row" style={{ marginTop: 50, marginBottom: 100 }}>

                                <div className="col-lg-4 col-lg-offset-4 col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3 col-xs-12">
                                    <a target="_blank" className="btn btn-success btn-block btn-lg" href="https://dashboard.commentbox.io">Sign Up in the Dashboard</a>
                                    <hr />
                                </div>

                            </div>

                        </div>


                    </section>

                    <section id="demo-container">

                        <div className="header">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="content">
                                            <h3>
                                                Got a question? Ask below!
                                            </h3>
                                            <h4>
                                                Feedback is awesome too.
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12">

                                    <div className="panel panel-default">
                                        <div className="panel-body">

                                            <img id="demo" className="graphic hidden-sm hidden-xs" src={graphic} alt="People making comments" />

                                            <div className="commentbox" />

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </section>

                </div>
            </MainLayout>
        );
    }
}

export default HomePage;
