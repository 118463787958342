import React from 'react';
import professional from "../../../images/professional.svg";

class PaidPlan extends React.Component {

    state = {
        plan: 'monthly'
    };

    onChangePlan = ({ currentTarget: { value: plan } }) => {

        this.setState({ plan });
    };


    render() {
        return (
            <div className={`plan pull-left ${this.props.className}`}>

                <h3>
                    Professional
                </h3>

                <hr />

                <img src={professional} alt="Business person" />

                <h2>
                    {this.state.plan === 'monthly' ? '$10 / month' : '$100 / year'}
                </h2>
                <div className="sub">
                    <div className="btn-group btn-group-justified" role="group" >
                        <div className="btn-group" role="group">
                            <button
                                type="button"
                                className={`btn btn-xs ${this.state.plan === 'monthly' ? 'btn-success' : 'btn-default'}`}
                                value="monthly"
                                onClick={this.onChangePlan}
                            >
                                Monthly
                            </button>
                        </div>
                        <div className="btn-group" role="group">
                            <button
                                type="button"
                                className={`btn btn-xs ${this.state.plan === 'yearly' ? 'btn-success' : 'btn-default'}`}
                                value="yearly"
                                onClick={this.onChangePlan}
                            >
                                Yearly
                            </button>
                        </div>

                    </div>
                </div>

                <ul>
                    <li>
                        Unlimited comments
                    </li>
                    <li>
                        Unlimited page views
                    </li>
                    <li>
                        Basic moderation tools
                    </li>
                    <li>
                        Webhook support
                    </li>
                    <li>
                        Slack notifications
                    </li>
                    <li>
                        Single sign-on
                    </li>
                    <li>
                        2 months free with yearly plan
                    </li>
                </ul>

            </div>
        );
    }
}

export default PaidPlan;