import React from 'react';
import Prism from 'prismjs';

import './Code.scss';

class Code extends React.Component {

    content = React.createRef();

    componentDidMount() {
        Prism.highlightAllUnder(this.content.current);
    }
    componentDidUpdate() {
        Prism.highlightAllUnder(this.content.current);
    }

    render() {
        return (
            <div className="code" ref={this.content}>
                <pre>
                <code className={`language-${this.props.language}`}>
                    {this.props.content}
                </code>
                </pre>
            </div>
        );
    }
}

export default Code;