import React from 'react';

class ActionLink extends React.Component {

    onClick = e => {

        const el = document.getElementById(this.props.href.replace('#', ''));
        if (el.scrollIntoView !== undefined) {
            e.preventDefault();
            el.scrollIntoView({ behavior: 'smooth' });
        }
    };

    render() {
        return (
            <a {...this.props} onClick={this.onClick}>
                {this.props.children}
            </a>
        );
    }
}

export default ActionLink;